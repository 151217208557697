$('.donate-ten').click(function(){
    $('#donate-main').val('EJP2P6D4A5RC4');
    $('.donate-btns .donate-btn').removeClass('donate-btn-dark');
    $(this).toggleClass('donate-btn-dark');
});
$('.donate-twenty').click(function(){
    $('#donate-main').val('Z8MP2LTT9YRGQ');
    $('.donate-btns .donate-btn').removeClass('donate-btn-dark');
    $(this).toggleClass('donate-btn-dark');
});
$('.donate-fifty').click(function(){
    $('#donate-main').val('L36S4TC2NMF8Y');
    $('.donate-btns .donate-btn').removeClass('donate-btn-dark');
    $(this).toggleClass('donate-btn-dark');
});
$('.donate-hundred').click(function(){
    $('#donate-main').val('RMDGB5KM2R7R4');
    $('.donate-btns .donate-btn').removeClass('donate-btn-dark');
    $(this).toggleClass('donate-btn-dark');
});
$('.donate-other').click(function(){
    $('#donate-main').val('Z5WJEDZF6BK8U');
    $('.donate-btns .donate-btn').removeClass('donate-btn-dark');
    $(this).toggleClass('donate-btn-dark');
});
