$(document).ready(function() {
    $('.wpcf7-checkbox .wpcf7-list-item').click(function() {

		var checkbox = $(this).children('input');

        if($(checkbox).is(":checked"))
        {	$(checkbox).prop('checked','');
            $(this).parents('.wpcf7-checkbox').removeClass('checked');
        } else {
			$(checkbox).prop('checked','checked');
            $(this).parents('.wpcf7-checkbox').addClass('checked');
        }
    });
});